body{
  background-color: #f3f3f3;
  font-family: Helvetica, Arial, sans-serif;
}

.dm-pageHeader{
  color:#4a4a4a;
  margin: 28px 0 10px 0;
  padding:0;
  border-bottom: none;
}

.dm-font-header-bold {
  font-size: 32px;
  font-weight: 700;
}
.busstop_route_div_button_disabled {
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  color: gray;
  background-color: #f0f0f0;
  display: block;
  min-height: 50px;
  padding:10px;
  position: relative;
  text-decoration: none;
  margin: 3px 0;
  border-radius: 0.25rem;
}

.busstop_route_div_button {
  border-width: 1px;
  border-style: solid;
  border-color: #0f61a9;
  background-color: white;
  display: block;
  min-height: 50px;
  padding:10px;
  position: relative;
  text-decoration: none;
  margin: 3px 0;
  border-radius: 0.25rem;
}

div.busstop_route_div_button:hover {
  background-color: #0F61A9;
  color:white;
}

.custom_home_page_link_button {
  border-bottom: 3px solid #0f61a9;
  background-color: white;
  display: block;
  min-height: 112px;
  padding:16px 18px 23px;
  position: relative;
  text-decoration: none;
  margin: 20px 0;
}

a.custom_home_page_link_button:link, a.custom_home_page_link_button:active, a.custom_home_page_link_button:visited  {
  background-color: white;
  text-decoration: none;
  color: #4a4a4a;
}

a.custom_home_page_link_button:hover {
  background-color: #0F61A9;
  color:white;
}

.custom_home_page_line_title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
}

.overlaymodal {
  width: 300px;
  height: 200px;
  line-height: 200px;
  position: fixed;
  top: 50%; 
  left: 50%;
  margin-top: -100px;
  margin-left: -150px;
  border-radius: 5px;
  text-align: center;
  z-index: 11; /* 1px higher than the overlay layer */
}
